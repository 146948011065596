<div class="h-full">
    <div
        id="app-sidebar-7"
        class="h-full surface-section left-0 top-0 z-10 border-right-1 surface-border select-none animation-duration-300 animation-ease-in-out"
        style="backdrop-filter: blur(10px)">
        <div class="flex flex-column h-full overflow-y-auto">
            <ul class="list-none px-3 pb-3 pt-0 m-0">
                <li>
                    <div
                        class="p-3 flex flex-row lg:flex-column align-items-center cursor-pointer lg:justify-content-center text-cyan-600 transition-duration-150 transition-colors">
                        <img src="images/logo-luis-quer.svg" alt="LUIS" height="90vh" />
                    </div>
                </li>
                <li>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a
                                pRipple
                                (click)="changeComponent('', '', 1, '', null)"
                                class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-cyan-700">
                                <i class="pi pi-home mr-2"></i>
                                <span class="font-medium">{{ 'dashboard' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <p-divider></p-divider>
            <ul class="list-none p-3 m-0 h-full">
                <ng-container *ngFor="let product of productsMenu">
                    <li *hasPermission="null; product: product.product">
                        <div
                            [ngClass]="{
                                'surface-300': selectedProductTitle === product.product,
                            }"
                            class="p-3 flex align-items-center justify-content-between text-600">
                            <span class="font-medium text-xl text-900">{{
                                product.displayTranslationKey | translate
                            }}</span>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <ng-container *ngFor="let subItem of product.subMenu">
                                <li *hasPermission="subItem.permission">
                                    <a
                                        [ngClass]="{
                                            'surface-200':
                                                selectedMenuItemId === product.id &&
                                                selectedSubMenuItemTitle === subItem.subMenuTitle,
                                        }"
                                        pRipple
                                        (click)="
                                            changeComponent(
                                                subItem.routingKey,
                                                subItem.displayTranslationKey,
                                                1,
                                                product.id,
                                                subItem.productCategory,
                                                product,
                                                subItem.subMenuTitle
                                            )
                                        "
                                        class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                        <i class="pi {{ subItem.icon }} mr-2"></i>
                                        <span class="font-medium">{{ subItem.displayTranslationKey | translate }}</span>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
                <li *ngIf="productsMenu.length >= 1">
                    <p-divider></p-divider>
                </li>
                <li *ngIf="baseMenu">
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li *ngFor="let baseItem of baseMenu">
                            <a
                                *hasPermission="baseItem.minRole"
                                [ngClass]="{
                                    'surface-200': selectedMenuItemId === baseItem.id,
                                }"
                                pRipple
                                (click)="
                                    changeComponent(baseItem.routingKey, baseItem.displayTranslationKey, 0, baseItem.id)
                                "
                                class="flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                <i class="pi {{ baseItem.icon }} mr-2"></i>
                                <span class="font-medium">{{ baseItem.displayTranslationKey | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
