import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductCategory, ProductSubMenuTitle, ProductTitle, State } from '@luis/common/shared';
import { Observable, shareReplay } from 'rxjs';
import { PRODUCT_MENU } from './models/product-menu.model';
import { SidebarItem } from './models/sidebar-item.model';
import { SidebarState } from './models/sidebar-state.model';

const initialState: SidebarState = {
    productMenu: [],
    baseMenu: [],
    selectNavigationItem: '',
    selectProductSubNavigationItem: undefined,
    selectedProductTitle: undefined,
};

@Injectable({
    providedIn: 'root',
})
export class SidebarService extends State<SidebarState> {
    productMenu$: Observable<SidebarItem[]> = this.select((state) => state.productMenu).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    baseMenu$: Observable<SidebarItem[]> = this.select((state) => state.baseMenu).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    selectProductNavigationItem$: Observable<string> = this.select((state) => state.selectNavigationItem).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    selectProductSubNavigationItem$: Observable<ProductSubMenuTitle> = this.select(
        (state) => state.selectProductSubNavigationItem,
    ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    selectedProductTitle$: Observable<ProductTitle> = this.select((state) => state.selectedProductTitle).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(public router: Router) {
        super(initialState);
        this.restoreProductsMenu();
    }

    private restoreProductsMenu() {
        const product = localStorage.getItem('lastProductsMenu');
        this.setProductsMenu(product ?? 'travel-insurance');
    }

    setProductsMenu(product: string) {
        if (PRODUCT_MENU[product.toLocaleLowerCase()]) {
            this.setState({
                productMenu: PRODUCT_MENU[product.toLowerCase()],
            });
            localStorage.setItem('lastProductsMenu', product);
        }
    }

    getProductsMenu() {
        return this.state.productMenu;
    }

    setSelectNavigationItem(id: string) {
        this.setState({ selectNavigationItem: id });
    }

    setSelectProductSubNavigationItem(subMenuTitle: ProductSubMenuTitle) {
        this.setState({ selectProductSubNavigationItem: subMenuTitle });
    }

    setBaseSidebarMenu() {
        const baseMenu: SidebarItem[] = [
            {
                id: 'basemenu_notifications',
                name: 'notifications',
                icon: 'pi-envelope',
                productCategory: ProductCategory.TRAVEL_INSURANCE,
                displayTranslationKey: 'sidebar.base.notifications',
                routingKey: 'notifications',
                minRole: 'read_contract',
            },
            {
                id: 'basemenu_consistency_checks',
                name: 'consistency-checks',
                icon: 'pi-exclamation-circle',
                productCategory: ProductCategory.TRAVEL_INSURANCE,
                displayTranslationKey: 'sidebar.base.consistency-checks',
                routingKey: 'consistency-checks',
                minRole: 'update_contract',
            },
        ];

        this.setState({
            baseMenu: [...baseMenu],
        });
    }

    getBaseSidebarMenu() {
        return this.state.baseMenu;
    }

    setProductTitle(title: ProductTitle) {
        this.setState({ selectedProductTitle: title });
    }
}
